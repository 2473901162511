<template>
  <ClassRoomNavbar @addClassRoom="false" @rerenderPage="initNavbarData" />
  <el-main class="text-center">
    <div
      class="container"
      style="padding: 0; max-width: 720px !important"
      id="top"
    >
      <el-breadcrumb
        v-if="$route.name == 'ChargeMeContactBook_HomeWork'"
        separator="/"
        class=""
      >
        <el-breadcrumb-item :to="{ path: '/HomeWork' }"
          >作業清單</el-breadcrumb-item
        >
      </el-breadcrumb>
      <div class="homework mt-3">
        <div class="homework-toolbar">
          <div
            class="chooseRole text-end mb-3 d-flex justify-content-between align-content-center"
          >
            <el-switch
              v-if="UserRole == 'Teacher'"
              v-model="showNotAssignee"
              active-color=" #ff4949"
              active-text="未發派"
              inactive-text="已發派"
              inactive-color="#13ce66"
              :active-value="true"
              :inactive-value="false"
              size="small"
            >
            </el-switch>
            <el-radio-group
              v-model="UserRole"
              size="small"
              class="custom-radio-group ms-auto"
              fill="#453D26"
            >
              <el-radio-button
                label="Teacher"
                class="custom-radio-button"
                @click.prevent="UserRole = 'Teacher'"
                >我是老師</el-radio-button
              >
              <el-radio-button
                label="Student"
                class="custom-radio-button"
                @click.prevent="UserRole = 'Student'"
                >我是學生</el-radio-button
              >
              <el-radio-button
                label="Parent"
                class="custom-radio-button"
                @click.prevent="UserRole = 'Parent'"
                >我是家長</el-radio-button
              >
            </el-radio-group>
          </div>

          <div class="totalOfKind">
            <div
              class="kindItem"
              @click="
                // initHomeWorkData(UserRole);
                homeworkStatus = '';
                showDelay = false;
              "
              :class="{
                activeKind: showDelay == false && homeworkStatus == '',
              }"
            >
              <div class="btnCircle">
                <i class="fal fa-book"></i>
              </div>
              <p class="itemTitle">作業總數</p>
              <p class="itemCount">{{ homeworkCount_total.length }}</p>
            </div>
            <!-- <div
              class="kindItem"
              @click="
                showDelay = true;
                homeworkStatus = '';
              "
              :class="{ activeKind: showDelay == true && homeworkStatus == '' }"
            >
              <div class="btnCircle" style="background-color: #fc4001">
                <i class="fas fa-exclamation"></i>
              </div>
              <p class="itemTitle">已逾期數</p>
              <p class="itemCount">
                {{ homeworks.filter((x) => x.isDelay).length }}
              </p>
            </div> -->
            <div
              class="kindItem"
              @click="homeworkStatus = '已完成'"
              :class="{ activeKind: homeworkStatus == '已完成' }"
            >
              <div class="btnCircle" style="background-color: #f3d257">
                <i class="fas fa-check"></i>
              </div>
              <p class="itemTitle">已完成數</p>
              <p class="itemCount">
                {{ homeworkCount_total.filter((x) => x.isDone).length }}
              </p>
            </div>
            <div
              class="kindItem"
              @click="homeworkStatus = '未完成'"
              :class="{ activeKind: homeworkStatus == '未完成' }"
            >
              <div class="btnCircle" style="background-color: #e55c4f">
                <i class="fas fa-times"></i>
              </div>
              <p class="itemTitle">未完成數</p>
              <p class="itemCount">
                {{ homeworkCount_total.filter((x) => !x.isDone).length }}
              </p>
            </div>
          </div>
          <el-divider class="my-1 d-inline-block" />
          <div class="d-flex align-items-center mb-2">
            <el-select
              v-model="selectedCourse"
              placeholder="篩選課程"
              clearable
              size="small"
              class="custom-select me-1"
              @change="updateContactBookTitles"
            >
              <el-option
                v-for="course in uniqueCourses"
                :key="course.className"
                :label="`${course.className}(${course.count})`"
                :value="course.className"
              ></el-option>
            </el-select>
            <el-select
              v-model="selectedContactBookCreateDate"
              placeholder="選擇日期"
              clearable
              size="small"
              class="custom-select me-1"
            >
              <el-option
                v-for="title in filteredContactBookCreateDate"
                :key="title"
                :label="title"
                :value="title"
              ></el-option>
            </el-select>
            <el-select
              v-if="UserRole == 'Teacher' || UserRole == 'Parent'"
              v-model="selectStudent"
              placeholder="選擇學生"
              clearable
              size="small"
              class="custom-select"
            >
              <el-option
                v-for="i in studentList"
                :key="i"
                :label="i"
                :value="i"
              ></el-option>
            </el-select>
          </div>
          <!-- <div class="d-flex align-items-center mt-2"></div> -->
        </div>

        <div class="d-flex my-2 align-items-center">
          <el-dropdown class="me-auto">
            <el-tag type="primary">
              <span>顯示: {{ dateRange_chinese }} 內</span>
              <i class="ms-2 fa-solid fa-bars-filter"></i>
            </el-tag>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="dateRange = 0"
                  >一個月</el-dropdown-item
                >
                <el-dropdown-item @click="dateRange = 1"
                  >三個月</el-dropdown-item
                >
                <el-dropdown-item @click="dateRange = 2">一年</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <el-dropdown
            class="ms-auto"
            v-if="UserRole.toLowerCase() == 'teacher'"
          >
            <el-tag type="info">
              <span>{{ allOpen ? "全部展開" : "全部收合" }}</span>
            </el-tag>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="allOpen = true"
                  >全部展開</el-dropdown-item
                >
                <el-dropdown-item @click="allOpen = false"
                  >全部收合</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <el-tag
            v-if="homeworkStatus == '未完成'"
            type="info"
            @click="showDelay = !showDelay"
            :class="{ showDelay: showDelay }"
            class="ms-2"
          >
            <span class="">僅顯示逾期</span>
          </el-tag>

          <el-tag type="info ms-2" @click="sortByDate = !sortByDate">
            <span class="me-1">日期</span>
            <i v-if="sortByDate" class="far fa-angle-up"></i>
            <i v-else class="far fa-angle-down"></i>
          </el-tag>
        </div>

        <div class="homework-list">
          <template v-if="UserRole.toLowerCase() == 'teacher'">
            <div
              v-for="t in teacherViwer"
              :key="t"
              class="homework-card flex-wrap"
              shadow="never"
              stlye="padding:5px"
            >
              <div class="homework-info position-relative w-100">
                <el-tag
                  v-if="t[0].existFile"
                  round
                  class="hasfileIcon"
                  size="small"
                  type="success"
                >
                  <i class="fa-regular fa-circle-info me-1"></i>
                  <span>有檔案</span>
                </el-tag>
                <el-tag
                  v-if="t[0].contactTodoContent"
                  round
                  class="hasfileIcon"
                  size="small"
                  type="success"
                >
                  <i class="fa-regular fa-circle-info me-1"></i>
                  <span>有內文</span>
                </el-tag>
                <div class="d-flex flex-column mt-1 w-100">

                  <h3
                    class="custom-router-link todohw d-flex" 
                    @click="showTaskAsync(t[0])"
                  >
                  <i v-if="t[0].isNecessary" class="fas fa-bookmark me-2" style="color: #96beab"></i> 

                    {{ t[0].contactTodoTitle }}
                  </h3>

                  <span class="mt-1 d-flex align-items-center">
                    {{ t[0].classRoomName }}，{{ t[0].contactBookTitle }}，{{
                      t[0].contactBookCreateDate
                    }}
                    <i class="far fa-arrow-right mx-1"></i>
                    <router-link
                      :to="`/ChargeMeContactBook/ClassRoomCenter/ClassRoom/${t[0].classRoomId}/ContactBookView/${UserRole}/${t[0].contactBookId}`"
                      class="link"
                    >
                      <i class="text-primary fal fa-book"></i>
                    </router-link>
                    <span
                      class="pt-1"
                      v-if="t[0].dueDate != ''"
                      :class="{ isDelay: t[0].isDelay && !t[0].isDone }"
                      >，
                      <i class="fal fa-clock me-1"></i>
                      <span>{{ t[0].dueDate }}</span>
                    </span>
                    <span class="ms-auto" style="font-size: xx-small"
                      >{{ t.filter((x) => x.isDone).length }} /
                      {{ t.length }}</span
                    >

                    <el-button
                      v-if="!showNotAssignee"
                      class="InfoBtn ms-2"
                      type="button"
                      circle
                      data-bs-toggle="collapse"
                      :data-bs-target="
                        '#todo_' +
                        t[0].contactTodoId +
                        '_' +
                        t[0].contactTodoAssigneId
                      "
                      aria-expanded="false"
                      :aria-controls="
                        'todo_' +
                        t[0].contactTodoId +
                        '_' +
                        t[0].contactTodoAssigneId
                      "
                    >
                      <i class="far fa-angle-down"></i>
                    </el-button>
                  </span>
                  <el-progress
                    :percentage="
                      (t.filter((x) => x.isDone).length / t.length) * 100
                    "
                  >
                  </el-progress>
                  <div
                    :class="{ show: allOpen }"
                    class="collapse w-100"
                    :id="
                      'todo_' +
                      t[0].contactTodoId +
                      '_' +
                      t[0].contactTodoAssigneId
                    "
                  >
                    <div class="mt-2">
                      <div v-for="st in t" :key="st">
                        <div
                          v-if="st.contactTodoAssigneId != -999"
                          class="d-flex align-items-center mt-1 py-1 border-bottom"
                        >
                          <i
                            v-if="st.isDone"
                            class="fal fa-check-square me-2 text-success"
                            style="zoom: 1"
                            @click="showEditHomeWorkIsDoneConfirm(st)"
                          ></i>
                          <i
                            v-else
                            class="fal fa-square me-2"
                            style="zoom: 1"
                            @click="showEditHomeWorkIsDoneConfirm(st)"
                          ></i>
                          <el-avatar
                            size="small"
                            :src="st.contactTodoAssigneAvatar"
                          ></el-avatar>
                          <p class="ms-1">
                            {{ st.contactTodoAssigneName }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template v-else>
            <div
              v-for="homework in filteredHomeworks"
              :key="homework.id"
              class="homework-card flex-wrap"
              shadow="never"
              stlye="padding:5px"
            >
              <div class="homework-info w-100">
                <el-tag
                  v-if="homework.existFile"
                  round
                  class="hasfileIcon"
                  size="small"
                  type="success"
                >
                  <i class="fa-regular fa-circle-info me-1"></i>
                  <span>有檔案</span>
                </el-tag>
                <el-tag
                  v-if="homework.contactTodoContent"
                  round
                  class="hasfileIcon"
                  size="small"
                  type="success"
                >
                  <i class="fa-regular fa-circle-info me-1"></i>
                  <span>有內文</span>
                </el-tag>
                <div class="todoTitle">
                  
                  <i
                    v-if="homework.isDone"
                    class="fal fa-check-square me-2 text-success"
                    style="zoom: 1"
                    @click="showEditHomeWorkIsDoneConfirm(homework)"
                  ></i>
                  <i
                    v-else
                    class="fal fa-square me-2"
                    style="zoom: 1"
                    @click="showEditHomeWorkIsDoneConfirm(homework)"
                  ></i>
                </div> 

                <div class="d-flex flex-column mt-1 w-100">
                  <h3
                    @click="showTaskAsync(homework)"
                    class="custom-router-link todohw d-flex"
                  >
                  <i v-if="homework.isNecessary" class="fas fa-bookmark me-2" style="color: #96beab"></i> 
                    <el-tag
                      v-if="UserRole.toLowerCase() == 'parent'"
                      class="me-1"
                      type="warning"
                      size="small"
                      >{{ homework.contactTodoAssigneName }}</el-tag
                    >
                    {{ homework.contactTodoTitle }}
                  </h3>

                  <span class="mt-1">
                    {{ homework.classRoomName }}，{{
                      homework.contactBookTitle
                    }}，{{ homework.contactBookCreateDate }}
                    <i class="far fa-arrow-right mx-1"></i>
                    <router-link
                      :to="`/ChargeMeContactBook/ClassRoomCenter/ClassRoom/${homework.classRoomId}/ContactBookView/${UserRole}/${homework.contactBookId}`"
                      class="link"
                    >
                      <i class="text-primary fal fa-book"></i>
                    </router-link>
                    <span
                      class="pt-1"
                      v-if="homework.dueDate != ''"
                      :class="{ isDelay: homework.isDelay && !homework.isDone }"
                      >，
                      <i class="fal fa-clock me-1"></i>
                      <span>{{ homework.dueDate }}</span>
                    </span>
                  </span>
                </div>
                <div class="ms-auto d-flex">
                  <div
                    class="d-flex align-items-center me-2"
                    v-if="
                      UserRole == 'Teacher' &&
                      homework.contactTodoAssigneName != ''
                    "
                  >
                    <el-avatar
                      size="small"
                      :src="homework.contactTodoAssigneAvatar"
                    ></el-avatar>
                    <p class="ms-1">
                      {{ homework.contactTodoAssigneName }}
                    </p>
                  </div>
                  <!-- <el-button
                    v-if="homework.contactTodoContent != ''"
                    class="InfoBtn"
                    type="button"
                    circle
                    data-bs-toggle="collapse"
                    :data-bs-target="
                      '#todo_' +
                      homework.contactTodoId +
                      '_' +
                      homework.contactTodoAssigneId
                    "
                    aria-expanded="false"
                    :aria-controls="
                      'todo_' +
                      homework.contactTodoId +
                      '_' +
                      homework.contactTodoAssigneId
                    "
                  >
                    <i class="far fa-angle-down"></i>
                  </el-button> -->
                </div>
              </div>
              <div
                class="collapse w-100 mt-2"
                :id="
                  'todo_' +
                  homework.contactTodoId +
                  '_' +
                  homework.contactTodoAssigneId
                "
              >
                <div class="">
                  <div
                    v-if="homework.contactTodoContent != ''"
                    class="todoContent"
                    v-html="homework.contactTodoContent"
                  ></div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <el-dialog
      v-model="showTask"
      :width="$store.getters.el_mobileSize ? '100%' : '60%'"
      :before-close="handleClose"
      :fullscreen="$store.getters.el_mobileSize ? true : false"
    >
      <div class="main_space mt-3">
        <div class="top">
          <div class="up">
            <div class="taskTitleDiv">
              <p class="fw-bold">
                {{ taskInfo.title }}
              </p>
            </div>
          </div>
          <div class="edit_group">
            <div class="taskTitle">
              <span class="">資訊</span>
            </div>

            <div>
              <div v-if="taskInfo.content == ''">
                <div style="font-size: x-small">尚無描述...</div>
              </div>
              <div v-else>
                <div v-html="taskInfo.content"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="fileTitle">檔案</div>
        <div v-if="taskInfo.fileList.length != 0">
          <div
            class="mt-2 py-1 px-2 d-flex justify-content-between align-items-center"
            v-for="item in taskInfo.fileList"
            :key="item"
          >
            <i
              v-if="!IsImageFile(item.name) && !IsVideoFile(item.name)"
              class="fad fa-file me-2"
              style="
                --fa-primary-color: #398d69;
                --fa-secondary-color: #9cbdab;
                --fa-secondary-opacity: 1;
              "
            ></i>

            <el-image
              class="me-auto"
              v-if="IsImageFile(item.name)"
              style="width: 200px"
              :src="item.url"
              :preview-src-list="[item.url]"
            >
            </el-image>

            <VideoPlayer
              class="me-auto"
              v-else-if="IsVideoFile(item.name)"
              :src="item.url"
              controls
              :loop="true"
              width="200"
            />

            <el-link v-else class="me-auto" @click="previewFile(item)">{{
              item.name
            }}</el-link>
            <div>
              <i
                class="fa-regular fa-folder-arrow-down downloadFileIcon me-3"
                @click="downloadFile(item)"
              ></i>
            </div>
          </div>
        </div>
        <div v-else>尚無檔案...</div>
        <div class="linkTitle">連結</div>
        <div v-if="taskInfo.linkList.length != 0">
          <div class="linkList pt-2 px-1">
            <div
              v-for="item in taskInfo.linkList"
              :key="item"
              class="mb-2 d-flex justify-content-between"
            >
              <el-link type="primary" :href="item.url">{{
                item.title
              }}</el-link>
            </div>
          </div>
        </div>
        <div v-else>尚無連結...</div>
        <ClassRoomContactMessage
          :classRoomId="taskInfo.classRoomId"
          :memberOptions="memberOptions"
          :contactTodoId="taskInfo.id"
          :memberId="c_currentUser.id"
          :studentAssigneeId="
            studentAssigneeId == 'all' ? null : studentAssigneeId
          "
          :getAll="
            studentAssigneeId == 'all' &&
            (userRole == 'Teacher' || userRole == 'Owner')
              ? true
              : false
          "
          class="py-2"
          collapse=""
          type="ContactTodo"
        />
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
import ClassRoomNavbar from "../../../layout/Navbar.vue";
import { VideoPlayer } from "@videojs-player/vue";
import "video.js/dist/video-js.css";
import ClassRoomContactMessage from "../../../components/ChargeMeContactBook/ClassRoom/ClassRoomContactMessage.vue";

export default {
  data() {
    return {
      showTask: false,
      homeworks: [],
      filterKeyword: "",
      homeworkStatus: "",
      selectedCourse: "",
      selectedContactBookCreateDate: "",
      sortByDate: true,
      UserRole: "",
      selectStudent: "",
      showDelay: false,
      showNotAssignee: false,
      groupedData: [],
      dateRange: 0,
      allOpen: false,
      // teacherViwer: [],
      taskInfo: {
        title: "",
        content: "",
        checked: false,
        linkList: [],
        fileList: [],
        dueDate: "",
      },
      memberOptions: [],
    };
  },
  components: {
    ClassRoomNavbar,
    VideoPlayer,
    ClassRoomContactMessage,
  },
  computed: {
    c_currentUser() {
      return this.currentUser();
    },
    dateRange_chinese() {
      let result = "";
      switch (this.dateRange) {
        case 0:
          result = "一個月";
          break;
        case 1:
          result = "三個月";
          break;
        case 2:
          result = "一年";
          break;

        default:
          result = "一個月";
          break;
      }
      return result;
    },

    combinedProps() {
      let rusult = {
        selectedCourse: this.selectedCourse,
        homeworkStatus: this.homeworkStatus,
        selectedContactBookCreateDate: this.selectedContactBookCreateDate,
        UserRole: this.UserRole,
        selectStudent: this.selectStudent,
        showDelay: this.showDelay,
        showNotAssignee: this.showNotAssignee,
        dateRange: this.dateRange,
        allOpen: this.allOpen,
      };
      return rusult;
    },
    homeworkCount_total() {
      let filtered = this.homeworks;
      if (this.showNotAssignee) {
        filtered = filtered.filter(
          (homework) => homework.contactTodoAssigneId < 0
        );
      } else {
        filtered = this.homeworks.filter(
          (homework) => homework.contactTodoAssigneId > 0
        );
      }
      return filtered;
    },
    // homeworkCount_finish() {},
    // homeworkCount_notfinish() {},
    filteredHomeworks() {
      // 其他計算屬性...
      let filtered = this.homeworks;
      if (this.showNotAssignee) {
        filtered = filtered.filter(
          (homework) => homework.contactTodoAssigneId < 0
        );
      } else {
        filtered = this.homeworks.filter(
          (homework) => homework.contactTodoAssigneId > 0
        );
      }

      if (this.selectedContactBookCreateDate) {
        filtered = filtered.filter(
          (homework) =>
            homework.contactBookCreateDate ===
            this.selectedContactBookCreateDate
        );
      }

      if (this.homeworkStatus === "未完成") {
        filtered = filtered.filter((homework) => !homework.isDone);
      } else if (this.homeworkStatus === "已完成") {
        filtered = filtered.filter((homework) => homework.isDone);
      }

      if (this.selectedCourse) {
        filtered = filtered.filter(
          (homework) => homework.classRoomName === this.selectedCourse
        );
      }

      if (this.selectStudent != "") {
        filtered = filtered.filter((homework) =>
          homework.contactTodoAssigneName.includes(this.selectStudent)
        );
      }

      if (this.showDelay) {
        filtered = filtered.filter((homework) => homework.isDelay);
      }

      if (this.sortByDelay) {
        // 日期排序
        filtered = filtered.sort((a, b) => {
          return new Date(b.dueDate) - new Date(a.dueDate);
        });
      } else {
        filtered = filtered.sort((a, b) => {
          return new Date(a.dueDate) - new Date(b.dueDate);
        });
      }
      if (this.sortByDate) {
        // 日期排序
        filtered = filtered.sort((a, b) => {
          return (
            new Date(b.contactBookCreateDate) -
            new Date(a.contactBookCreateDate)
          );
        });
      } else {
        filtered = filtered.sort((a, b) => {
          return (
            new Date(a.contactBookCreateDate) -
            new Date(b.contactBookCreateDate)
          );
        });
      }

      return filtered;
    },
    uniqueCourses() {
      const classRoomNameCount = this.homeworkCount_total.reduce(
        (accumulator, homework) => {
          const classRoomName = homework.classRoomName;

          // 判斷 classRoomName 是否已經存在於 accumulator 中
          if (accumulator[classRoomName]) {
            accumulator[classRoomName]++;
          } else {
            accumulator[classRoomName] = 1;
          }

          return accumulator;
        },
        {}
      );

      // 將結果轉換成物件陣列
      const resultArray = Object.keys(classRoomNameCount).map(
        (classRoomName) => {
          return {
            className: classRoomName,
            count: classRoomNameCount[classRoomName],
          };
        }
      );

      return resultArray;
    },
    filteredContactBookCreateDate() {
      const ContactBookCreateDates = Array.from(
        new Set(
          this.filteredHomeworks.map(
            (homework) => homework.contactBookCreateDate
          )
        )
      );
      return ContactBookCreateDates;
    },

    studentList() {
      const students = Array.from(
        new Set(this.filteredHomeworks.map((x) => x.contactTodoAssigneName))
      );
      return students;
    },
    teacherViwer() {
      //老師視角
      // 创建一个空对象，用于存储分组后的数据
      const groupedData = {};

      // 遍历数组中的每个对象
      this.filteredHomeworks.forEach((item) => {
        // 获取当前对象的 contactTodoId
        const contactTodoId = item.contactTodoId;

        // 如果该 contactTodoId 不存在于 groupedData 中，则创建一个空数组
        if (!groupedData[contactTodoId]) {
          groupedData[contactTodoId] = [];
        }

        // 将当前对象添加到相应的 contactTodoId 分组中
        groupedData[contactTodoId].push(item);
      });
      console.log(groupedData);
      const resultArray = Object.values(groupedData);

      console.log(resultArray);
      return resultArray;
    },
  },
  mounted() {
    const vm = this;
    this.log_PageInfo("HomeWork", "4");
    // 從 localStorage 中取回資料
    const storedFilterDataString = localStorage.getItem(
      "homeWorkFilterCondition"
    );
    let role = "";
    if (storedFilterDataString == null) {
      role = "Student";
      vm.UserRole = role;
    } else {
      // 將 JSON 字串轉換成物件
      const storedFilterData = JSON.parse(storedFilterDataString);
      // 將資料設定回相應的變數
      vm.selectedCourse = storedFilterData.selectedCourse;
      vm.homeworkStatus = storedFilterData.homeworkStatus;
      vm.selectedContactBookCreateDate =
        storedFilterData.selectedContactBookCreateDate;
      vm.UserRole = storedFilterData.UserRole;
      vm.selectStudent = storedFilterData.selectStudent;
      vm.showDelay = storedFilterData.showDelay;
      vm.showNotAssignee = storedFilterData.showNotAssignee;
      vm.dateRange = storedFilterData.dateRange;
      vm.allOpen = storedFilterData.allOpen;
      if (vm.dateRange == null || vm.dateRange == undefined) {
        vm.dateRange = 0;
      }
      role = vm.UserRole;
      vm.UserRole = role;
    }

    //vm.initHomeWorkData(role);

  },
  methods: {
    async initStudentData(classRoomId) {
      const vm = this;
      vm.fetchAPI(
        "get",
        `${process.env.VUE_APP_URL_API}/ClassRoom/Member/${classRoomId}`,
        {},
        false
      )
        .then((res) => {
          console.log("memberOptions", res.data);
          vm.memberOptions = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    previewFile(file) {
      if (file.response == undefined) {
        window.open(file.url);
      } else {
        window.open(file.response.url);
      }
    },
    downloadFile(file) {
      console.log(file);
      if (file["response"] != undefined) file = file.response;
      this.axios
        .get(file.url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: response.data.type });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = file.name;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    IsVideoFile(fileName) {
      const videoExtensions = /\.(mp4|avi|mov|mkv|wmv|flv)$/i;
      if (videoExtensions.test(fileName)) {
        return true;
      } else {
        return false;
      }
    },
    IsImageFile(fileName) {
      const imageExtensions = /\.(jpg|jpeg|png|gif|bmp)$/i;
      if (imageExtensions.test(fileName)) {
        return true;
      } else {
        return false;
      }
    },
    async showTaskAsync(todo) {
      let vm = this;
      vm.fetchAPI(
        "get",
        `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/ContactTodoId/${todo.contactTodoId}`
      ).then((res) => {
        console.log("taskInfo", res.data);
        vm.taskInfo = res.data;
        vm.taskInfo.classRoomId = todo.classRoomId;
        vm.showTask = true;
      });
      await vm.initStudentData(todo.classRoomId);
    },
    updateContactBookTitles() {
      if (this.selectedCourse) {
        const filteredTitles = this.homeworks
          .filter((homework) => homework.classRoomName === this.selectedCourse)
          .map((homework) => homework.contactBookTitle);
        this.filteredContactBookCreateDate = [...new Set(filteredTitles)];
        this.selectedContactBookCreateDate = "";
      } else {
        this.filteredContactBookCreateDate = [];
        this.selectedContactBookCreateDate = "";
      }
    },
    initNavbarData() {
      const vm = this;
      vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/ClassRoomCenter`)
        .then((res) => {
          vm.classRooms = [];
          if (res.data.length != 0) {
            res.data.classRoomList.forEach((x) => {
              vm.classRooms.push({
                id: x.classRoomId,
                className: x.classRoomName,
                backgroundColor: x.backgroundColor,
                creator: {
                  name: x.teacherName,
                  avatar: x.teacherAvatar,
                },
                isTop: x.isTop,
                isArchive: x.isArchive,
                tags: x.tags,
                isDeleted: x.isDeleted,
                permission: x.permission,
              });
            });
            vm.usersTags = res.data.userTagList;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    initHomeWorkData(role) {
      let vm = this;
      vm.fetchAPI(
        "get",
        `${process.env.VUE_APP_URL_API}/HomeWork/GetHomeWorkByMySelf?role=${role}&dateRange=${vm.dateRange}`
      ).then(function (res) {
        vm.homeworks = res.data;
        console.log(res.data);
      });
    },
    showEditHomeWorkIsDoneConfirm(o) {
      let vm = this;
      let comofrmMsg = "";
      if (o.isDone) {
        comofrmMsg = "確定要將此繳交的作業收回嗎?";
      } else {
        comofrmMsg = "確定完成了這項作業嗎？";
      }
      vm.$confirm(comofrmMsg, "確定", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          vm.fetchAPI(
            "put",
            `${
              process.env.VUE_APP_URL_API
            }/ClassRoom/ContactBook/ContactTodo/IsDone?assigneeId=${
              o.contactTodoAssigneId
            }&isDone=${!o.isDone}`
          )
            .then((res) => {
              o.isDone = !o.isDone;
              console.log(res.data);
              vm.$message({
                message: "更改成功",
                type: "success",
              });
            })
            .catch((err) => {
              console.log(err.response.data);
              vm.notify("error", `更新失敗！`);
            });
        })
        .catch(() => {
          // 取消按钮被点击时触发的回调函数
          console.log("取消删除");
        });
    },
  },
  watch: {
    dateRange() {
      this.initHomeWorkData(this.UserRole);
    },
    // allOpen() {
    //   this.initHomeWorkData(this.UserRole);
    // },
    UserRole(newVal) {
      if (newVal.toLowerCase() == "student") {
        this.showNotAssignee = false;
      }
    },
    combinedProps(newVal, oldVal) {
      const filterData = {
        selectedCourse: newVal.selectedCourse,
        homeworkStatus: newVal.homeworkStatus,
        selectedContactBookCreateDate: newVal.selectedContactBookCreateDate,
        UserRole: newVal.UserRole,
        selectStudent: newVal.selectStudent,
        showDelay: newVal.showDelay,
        showNotAssignee: newVal.showNotAssignee,
        dateRange: newVal.dateRange,
        allOpen: newVal.allOpen,
      };
      console.log(filterData);
      // 將物件轉換成 JSON 字串
      const filterDataString = JSON.stringify(filterData);
      localStorage.setItem("homeWorkFilterCondition", filterDataString);
      if (newVal.UserRole != oldVal.UserRole) {
        this.initHomeWorkData(this.UserRole);
      }
    },
  },
};
</script>

<style scoped>
.custom-dialog {
  max-width: 500px;
}
.totalOfKind {
  display: flex;
  justify-content: space-between;
  .kindItem {
    padding-top: 0.25rem;
    width: 33.33%;
    text-align: center;
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    cursor: pointer;
    .itemTitle,
    .itemCount {
      font-size: x-small;
      margin-bottom: 0.25rem;
    }
    .itemCount {
      font-weight: 600;
    }
    .btnCircle {
      margin: auto;
      color: white;
      line-height: 30px;
      width: 30px;
      height: 30px;
      background-color: #9cbdab;
      border-radius: 99em;
      margin-bottom: 0.5rem;
    }
  }
  .activeKind {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }
}
.InfoBtn {
  width: 20px;
  height: 20px;
  border-radius: 99em;
  font-size: xx-small;
  color: #77724b;
}
.isDelay {
  color: red !important;
}
.homework_card_isDelay {
  background-color: #ffe7e7;
  border: 1px solid red;
}
.showDelay {
  border: 1px solid black;
}
.custom-router-link {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  font-size: xx-small !important;
}
.custom-router-link:hover {
  text-decoration: underline;
  cursor: pointer;
}
.todoContent {
  max-height: 300px;
  padding: 0.5rem 0;
  overflow: auto;
}
.homework {
  /* 自定義樣式 */
}
.todoTitle {
  font-size: 1.5rem;
}
.homework-toolbar {
  /* margin-bottom: 10px; */
}

.homework-list {
}
.customShadow {
  box-shadow: 0 4px 3px rgba(0, 0, 0, 0.1);
}
.homework-card {
  background-color: #ffffff;
  padding: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  justify-content: space-between;
  text-align: start;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 0.5rem;
  font-size: small;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  /* padding: 0.5rem; */
}
.classTitle {
  font-size: medium;
  color: white;
  letter-spacing: -0.05em;
}
.homework-info {
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  position: relative;
}

.deadline {
  color: #999;
}
.todohw {
  font-size: medium !important;
  font-weight: bold;
  color: #6f6c63;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  word-wrap: break-word;
}
.homework-actions {
  margin-top: 10px;
}
.kindItem:first-child {
  border-left: none !important;
}
.todo_classRoom {
  color: black;
  font-size: large;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-left: 4px solid #ffd964;
  border-radius: 4px;
}
.todo_contactBook_createDate {
  color: black;
  font-size: medium;
  /* border: rgba(0, 0, 0, 0.3) solid 1px;
  border-radius: 8px;
  margin-top: 0.5rem; */
  /* margin-bottom: 0.5rem; */
  margin-left: 0.5rem;
  /* padding: 0.25rem 0.5rem; */
}
.todo_contactBook_contactBookName {
  color: black;
  font-size: medium;
  /* border: rgba(0, 0, 0, 0.3) solid 1px; */
  /* border-radius: 8px; */
  margin-left: 0.5rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  /* padding: 0.5rem; */
  .contactBookName {
    width: 100%;
    border-radius: 4px;
    display: flex;
    padding: 0.5rem;
    font-size: medium;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-left: 4px solid #409eff;
  }
}
.ContactBookCreateDate {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.3);

  border-left: 4px solid #398d69;
  display: inline-block;
  padding: 0.5rem;
  margin-top: 0.25rem;
  border-radius: 4px;
}
.todo-Card {
  /* width: 100%; */
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-left: 4px solid rgba(0, 0, 0, 0.5);
  padding: 0.5rem;
  margin-left: 0.5rem;
  margin-top: 0.25rem;
}
.todoCount {
  color: rgba(0, 0, 0, 0.5);
}
.hasfileIcon {
  position: absolute;
  top: 0px;
  right: -4px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}
</style>
<style lang="scss" scoped>
.editText {
  cursor: pointer;
  padding: 0.5rem 0;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
.taskTitleDiv {
  display: flex;
  font-size: x-large !important;
  word-break: normal;
  white-space: pre-warp;
  word-wrap: break-word;
  // font-weight: 800;
  p {
    max-width: 80vw;
    overflow: hidden;
  }
}
.taskTitle {
  display: flex;
  align-items: center;
}
.main_space {
  text-align: start;
  i {
    margin-right: 0.3em;
  }
  .arrow {
    cursor: pointer;
  }
  .top {
    color: #77724b !important;
    padding-bottom: 0.5rem;
    // border-bottom: 1px solid $main_gray;
    .up {
      display: flex;
      justify-content: space-between;
      // align-items: center;
    }
    .info {
      display: flex;

      justify-content: space-between;
      align-items: center;
      p {
        margin-right: 1rem;
        padding: 0.3rem 0.5rem;
        border-radius: 0.5rem;
      }

      .share,
      .date {
        display: flex;
        align-items: center;
      }
    }
  }
  .link,
  .assign,
  .file,
  .edit_space {
    // margin-top: 0.5rem;
    // border: 1px solid rgba(0, 0, 0, 0.15);
    // border-radius: 16px;
    // overflow: hidden;
    // box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
    // padding: 0.5rem;
    p {
      margin-bottom: 0.5rem;
    }
  }
  .assign,
  .file,
  .link {
    padding-bottom: 1rem;
    border-bottom: 1px solid $main_gray;
  }
  .taskTitle,
  .assignTitle,
  .fileTitle,
  .linkTitle {
    margin-bottom: 1rem;
    margin-top: 1rem;

    color: #453d26;
    font-weight: bolder;
    font-size: large !important;
    padding: 0.5rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
  .link {
    border-bottom: none;
    .linkList {
      i {
        cursor: pointer;
      }
    }
  }
  .assignLink {
    cursor: pointer;
  }
  .edit_group {
    padding: 0rem;
    margin-top: 1rem;
    // box-shadow: 1px 1px 3px 1px $main_gray;
    transition: 0.2s;
    &.focus {
      // box-shadow: 3px 3px 5px 3px #a2a1a1;
    }
  }
}

.deliverStatus {
  zoom: 1.5;
}
.IsDone {
  color: green;
}
:deep .editor .tox {
  z-index: 0 !important;
}
.removeFileIcon,
.downloadFileIcon {
  cursor: pointer;
  &:hover {
    color: #2c2c2c;
  }
}
@media screen and (max-width: 768px) {
  .info {
    flex-direction: column;
    align-items: flex-start !important;
    .first {
      flex-direction: column;
      align-items: flex-start !important;
      & > div,
      label {
        margin: 0 !important;
        // margin-bottom: 0.5rem !important;
      }
    }
  }
}
</style>
